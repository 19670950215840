import React from 'react';
import Player from 'react-player/youtube';
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Container,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import Deindex from '../../components/Deindex';

const TITLE = `1. Learn the content`;
const CAPTION = `Watch this video to learn from a real UX/UI designer and teacher`;
const SUMMARY = `
  By the end of this course, you will be able to list and define the stages of design thinking within the UX/UI context.
  As well as, identify the following keywords relative to each stage: “User research, Problem Statement, Wireframe, Prototype and Usability Testing”.
  To start, watch the video above and then click finished to move on.
`;
const CTA_LABEL = 'I finished it!';

const VIDEO_SRC = 'https://youtu.be/9Tqv5mQleSc';

const Page = (): React.ReactElement => {
  const { state } = useLocation();

  return (
    <Center flexDir="column" height="100vh" maxW="1440px" mx="auto" p={3}>
      <Deindex />
      <Container as={Stack} maxW="520px" p={0} spacing={4} textAlign="center">
        <Text textStyle="title1">{TITLE}</Text>
        <AspectRatio ratio={16 / 9}>
          <Player controls height="100%" url={VIDEO_SRC} width="100%" />
        </AspectRatio>
        <Text fontWeight="bold" textStyle="body4">
          {CAPTION}
        </Text>
        <Text textStyle="body5">{SUMMARY}</Text>
      </Container>
      <Box mt={4} textAlign="end" w="100%">
        <Button
          as={Link}
          rightIcon={<ArrowForwardIcon />}
          state={state}
          to={'/en/ux-shortcourse-step-2'}
          w={['100%', null, 'auto']}
        >
          {CTA_LABEL}
        </Button>
      </Box>
    </Center>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: { lng: { eq: "en" }, ns: { regex: "/(seo)/" } }
    ) {
      ...TranslationFragment
    }
  }
`;

export default Page;
